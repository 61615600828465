import { styled } from 'stitches.config';
import { PlayroomBaseModal } from '../PlayroomBaseModal';

type ConfirmLeaveModalProps = {
  onClose: () => void;
  onConfirm: () => void;
  isCreate?: boolean;
};

export const ConfirmLeaveModal = ({
  onClose,
  onConfirm,
  isCreate,
}: ConfirmLeaveModalProps) => {
  const handleConfirm = async () => {
    onConfirm();
  };

  return (
    <PlayroomBaseModal
      onClose={onClose}
      onConfirm={handleConfirm}
      title={
        isCreate
          ? '이미 참여중인 플레이룸이 있어요'
          : '참여중인 플레이룸이 있어요'
      }
      content={
        <>
          {isCreate ? (
            <Content>
              새로운 방을 만들 경우
              <br />
              현재 참여 중인 플레이룸에서 퇴장됩니다.
            </Content>
          ) : (
            <Content>
              새로운 플레이룸에 입장하실 경우
              <br />
              현재 참여 중인 플레이룸에서 퇴장됩니다.
            </Content>
          )}
        </>
      }
      okText={isCreate ? '새로 만들기' : '입장하기'}
      cancelText="취소"
    />
  );
};

const Content = styled('div', {
  color: '#515253',
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
});
