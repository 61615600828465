import {
  usePostExitRoomMutation,
  usePostJoinRoomMutation,
} from '@src/apis/mutation';
import { LoadingSpinner } from '@src/components/LoadingSpinner';
import Modal, { useModal } from '@src/components/Modal';
import { CheckPermissionModal } from '@src/components/Modal/play/CheckPermissionModal';
import { ConfirmLeaveModal } from '@src/components/Modal/play/ConfirmLeaveModal';
import { useAgora } from '@src/hooks/useAgora';
import { useQueryString } from '@src/hooks/useQueryString';
import { usePlayroomContext } from '@src/providers/Playroom';
import { useToastContext } from '@src/providers/Toast';
import { ApiFailResponseDto } from '@uniquegood/realworld-play-interface/dist';
import axios from 'axios';
import { navigate } from 'gatsby';
import React from 'react';
import { styled } from 'stitches.config';

export default function Invite({
  params,
}: {
  params: { id: string; shareCode: string };
}) {
  const { id, shareCode } = params;

  const { isPreparing } = useQueryString();
  const { showToast } = useToastContext();

  const {
    agoraContextData: { handleJoinOrLeave },
  } = useAgora();
  const { openSheet } = usePlayroomContext();

  const { openModal, closeModal, modal } = useModal();

  const { mutateAsync: joinRoom } = usePostJoinRoomMutation();
  const { mutateAsync: exitRoom } = usePostExitRoomMutation();

  const enterRoom = React.useCallback(async () => {
    try {
      const { data } = await joinRoom({ roomId: id, shareCode });

      if (data.success) {
        try {
          await navigator.mediaDevices.getUserMedia({ audio: true });

          handleJoinOrLeave(id);

          navigate(`/play/rooms`, { replace: true });

          openSheet();
        } catch (e) {
          console.error(e);

          openModal(
            <CheckPermissionModal
              onClose={closeModal}
              onConfirm={async () => {
                try {
                  // 권한 없을 때 처리
                  await exitRoom();

                  navigate(`/play/rooms`, { replace: true });
                } catch (e) {
                  console.error(e);
                }
              }}
            />
          );
        }
      }
    } catch (e) {
      if (axios.isAxiosError<ApiFailResponseDto>(e)) {
        const { errorTitle } = e.response?.data || {};

        switch (errorTitle) {
          case 'LimitRoomUserCount':
            await exitRoom();
            showToast('참여 가능 인원수가 초과되어 입장할 수 없어요.');
            break;
          case 'AlreadyEndedRoom':
            await exitRoom();
            showToast('이미 종료된 플레이룸이에요.');
            break;
          case 'AlreadyExistRoomUser':
            openModal(
              <ConfirmLeaveModal
                onClose={closeModal}
                onConfirm={async () => {
                  const data = await exitRoom();

                  if (data.success) {
                    await joinRoom({ roomId: id, shareCode });

                    handleJoinOrLeave(id);

                    navigate(`/play/rooms`, { replace: true });

                    openSheet();
                  }
                }}
              />
            );
            break;
          case 'AlreadyExistUserInRoom':
            navigate('/play/rooms', { replace: true });

            openSheet();
            break;
          default:
            break;
        }
      }
    }
  }, []);

  React.useEffect(() => {
    if (id && typeof id === 'string' && !isPreparing) {
      enterRoom();
    }
  }, [id, isPreparing]);

  return (
    <>
      <Container>
        <LoadingSpinner />
      </Container>
      <Modal {...modal} />
    </>
  );
}

const Container = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
