import { styled } from 'stitches.config';
import { BaseModal } from '../../BaseModal';

type CheckPermissionModalProps = {
  onClose: () => unknown;
  onConfirm: () => unknown;
};

export const CheckPermissionModal = ({
  onClose,
  onConfirm,
}: CheckPermissionModalProps) => {
  const handleConfirm = async () => {
    onConfirm();

    onClose();
  };

  return (
    <BaseModal onClose={onClose} title="권한 활성화가 필요해요!">
      <Content>
        다른 플레이어와의 소통을 위해
        <br /> 상단에 표시되는 마이크 권한을 허용해 주세요.
        <br />
        <br />
        권한 재설정이 필요할 경우,
        <br /> 브라우저 설정에서 변경할 수 있어요.
      </Content>
      <ControllerContainer>
        <ControlButton
          onClick={handleConfirm}
          style={{ backgroundColor: '#c869ff', color: '#fff' }}
        >
          확인
        </ControlButton>
      </ControllerContainer>
    </BaseModal>
  );
};

const ControllerContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '48px',
  marginTop: '24px',
});

const ControlButton = styled('button', {
  flex: 1,
  height: '100%',
  border: 'none',
  borderRadius: '999px',
  cursor: 'pointer',

  '&:first-of-type': {
    marginRight: '8px',
  },
});

const Content = styled('div', {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  color: '#515253',
  textAlign: 'center',
});
